import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = {
  key: 3,
  class: "text-muted"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, _toDisplayString(_ctx.subscription.gateway), 1),
    (_ctx.subscription.gateway_code)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.subscription.gateway.indexOf('paypal') > -1)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: `https://www.paypal.com/activity/payment/${_ctx.subscription.gateway_code}`,
                target: "_blank"
              }, [
                _createTextVNode(_toDisplayString(_ctx.subscription.gateway_code) + " ", 1),
                _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fad fa-external-link" }, null, -1))
              ], 8, _hoisted_2))
            : (_ctx.subscription.gateway.indexOf('pagarme') > -1)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  href: `https://beta.dashboard.pagar.me/#/transactions/${_ctx.subscription.gateway_code}`,
                  target: "_blank"
                }, [
                  _createTextVNode(_toDisplayString(_ctx.subscription.gateway_code) + " ", 1),
                  _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fad fa-external-link" }, null, -1))
                ], 8, _hoisted_3))
              : (_ctx.subscription.gateway.indexOf('stripe') > -1)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 2,
                    href: `https://dashboard.stripe.com/subscriptions/${_ctx.subscription.gateway_code}`,
                    target: "_blank"
                  }, [
                    _createTextVNode(_toDisplayString(_ctx.subscription.gateway_code) + " ", 1),
                    _cache[2] || (_cache[2] = _createElementVNode("i", { class: "fad fa-external-link" }, null, -1))
                  ], 8, _hoisted_4))
                : (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(_ctx.subscription.gateway_code), 1))
        ]))
      : _createCommentVNode("", true)
  ]))
}