
import {defineComponent} from "vue";
import DateTime from "@/components/common/DateTime.vue";
import MobileStatus from "@/components/common/MobileStatus.vue";
import SubscriptionLink from "@/components/common/SubscriptionLink.vue";
import Currency from "@/components/common/Currency.vue";
import MobileMoveSubscriptionModal from "@/components/modals/forms/MobileMoveSubscriptionModal.vue";
import MobileEditLicenceModal from "@/components/modals/forms/MobileEditLicenceModal.vue";
import Country from "@/components/common/Country.vue";
import Locale from "@/components/common/Locale.vue";
import FromNow from "@/components/common/FromNow.vue";

export default defineComponent({
  name: "widget-1",
  components: {
    FromNow,
    MobileMoveSubscriptionModal,
    MobileEditLicenceModal,
    Currency,
    SubscriptionLink,
    MobileStatus,
    Country,
    Locale,
    DateTime,
  },
  props: {
    widgetClasses: String,
    chartHeight: Number,
    mobiles: Array,
    mobile: Object,
  },
  setup(props, {emit}) {
    const callback = () => {
      emit('callback')
    }

    return {
      callback
    }
  }
});
