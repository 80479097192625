
import {defineComponent, ref} from "vue";
import Date from "@/components/common/Date.vue";
import Currency from "@/components/common/Currency.vue";
import SubscriptionGatewayLink from "@/components/common/SubscriptionGatewayLink.vue";
import SubscriptionStatus from "@/components/subscriptions/SubscriptionStatus.vue";
import SubscriptionEditModal from "@/components/modals/forms/SubscriptionEditModal.vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "subscription-records",
  props: {
    cardClasses: String,
    subscriptions: Array,
    mobiles: Array,
  },
  components: {
    SubscriptionStatus,
    SubscriptionGatewayLink,
    Currency,
    Date,
    SubscriptionEditModal
  },
  emits: {
    callback: () => true,
  },
  computed: {
    subscriptionsList() {
      let subscriptions = this.subscriptions
      return subscriptions?.reverse()
    }
  },
  setup(props, { emit }) {
    const processing = ref(false)

    const markAsPaid = (subscription): void => {
      processing.value = true
      ApiService.setHeader()
      ApiService.put(`v1/backend/subscriptions/mark-as-paid`, subscription)
          .then(() => {
            processing.value = false
            subscription.status = 1
          })
    }

    const callback = (): void => {
      emit('callback')
    }

    return {
      processing,
      markAsPaid,
      callback
    }
  }
});
