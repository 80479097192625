import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card-body p-6 row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileDetails = _resolveComponent("MobileDetails")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`card ${_ctx.cardClasses}`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mobilesList, (mobile) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "col-6 col-xxl-4",
          key: mobile.id
        }, [
          _createVNode(_component_MobileDetails, {
            onCallback: _ctx.callback,
            mobile: mobile,
            mobiles: _ctx.mobiles,
            "widget-classes": "card-xl-stretch mb-xl-8 w-100"
          }, null, 8, ["onCallback", "mobile", "mobiles"])
        ]))
      }), 128))
    ])
  ], 2))
}