
import {defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import NewCardModal from "@/components/modals/forms/NewCardModal.vue";
import PaymentRecords from "@/components/customers/cards/overview/PaymentRecords.vue";
import SubscriptionsRecords from "@/components/customers/cards/overview/SubscriptionsRecords.vue";
import DateTime from "@/components/common/DateTime.vue";
import Locale from "@/components/common/Locale.vue";

import Mobiles from "@/components/customers/Mobiles.vue";

import ApiService from "@/core/services/ApiService";
import {useRoute} from "vue-router";

export default defineComponent({
  name: "customer-details",
  components: {
    DateTime,
    Locale,
    PaymentRecords,
    SubscriptionsRecords,
    Mobiles,
    NewCardModal,
  },
  setup() {
    const route = useRoute()
    const customer = ref({
      orders: [],
      mobiles: [],
      subscriptions: [],
    })

    onMounted(() => {
      setCurrentPageBreadcrumbs('Cliente', ['Clientes']);
      load()
    });

    const load = (): void => {
      ApiService.setHeader();
      ApiService.get(`v1/backend/users/${route.params.customerId}`)
          .then(({data}) => {
            customer.value = data;
          })
          .catch(({response}) => {
            console.log(response);
          });
    };

    return {
      customer,
      load
    };
  },
});
