
import {defineComponent, ref} from "vue";
import {hideModal} from "@/core/helpers/dom";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "mobile-edit-licence-modal",
  props: {
    modalId: String,
    subscription: Object,
    mobiles: Array,
    mobileId: Number,
  },
  components: {},
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const MobileEditLicenceModal = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const formData = ref({
      mobile_id: props.mobileId,
      days: 0
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(() => {
        loading.value = true;

        ApiService.setHeader();
        const payload = {
          params: {
            mobileId: formData.value.mobile_id,
            days: formData.value.days,
          }
        }
        ApiService.post(`v1/backend/mobile/edit-licence`, payload)
            .then(() => {
              loading.value = false;
              formData.value.days = 0
              emit('callback')
              hideModal(MobileEditLicenceModal.value);
            })
            .catch(({response}) => {
              console.log(response);
            });
      });
    };

    return {
      formData,
      submit,
      formRef,
      loading,
      MobileEditLicenceModal,
    };
  },
});
