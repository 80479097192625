import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "badge badge-success"
}
const _hoisted_2 = {
  key: 1,
  class: "badge badge-light-danger"
}
const _hoisted_3 = {
  key: 2,
  class: "badge badge-light-danger"
}
const _hoisted_4 = {
  key: 3,
  class: "badge badge-light-danger"
}
const _hoisted_5 = {
  key: 4,
  class: "badge badge-light-warning"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.status === 'ACTIVE')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Ativa"))
      : (_ctx.status.indexOf('CANCELLED') > -1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Cancelada"))
        : (_ctx.status.indexOf('SUSPENDED') > -1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Suspensa"))
          : (_ctx.status.indexOf('EXPIRED') > -1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, "Expirada"))
            : (_ctx.status.indexOf('PENDING') > -1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, "Aguardando Pagamento"))
              : _createCommentVNode("", true)
  ]))
}