import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.subscription.gateway.indexOf('paypal') > -1)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: `https://www.paypal.com/billing/subscriptions/${_ctx.subscription.gateway_code}`,
          target: "_blank"
        }, [
          _createTextVNode(_toDisplayString(_ctx.subscription.gateway_code) + " ", 1),
          _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fad fa-external-link" }, null, -1))
        ], 8, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.subscription.gateway.indexOf('pagarme') > -1)
      ? (_openBlock(), _createElementBlock("a", {
          key: 1,
          href: `https://beta.dashboard.pagar.me/#/subscriptions/${_ctx.subscription.gateway_code}`,
          target: "_blank"
        }, [
          _createTextVNode(_toDisplayString(_ctx.subscription.gateway_code) + " ", 1),
          _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fad fa-external-link" }, null, -1))
        ], 8, _hoisted_2))
      : _createCommentVNode("", true)
  ]))
}