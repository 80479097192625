
import {defineComponent, ref} from "vue";

export default defineComponent({
  props: {
    country: { type: String, default: "-" },
    empty: { type: String, default: "-" },
  },
  setup(props) {
    const countryName = ref(props.country)

    return {
      countryName
    }
  }
});
