import { createElementVNode as _createElementVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-650px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-body py-10 px-lg-17" }
const _hoisted_5 = {
  class: "scroll-y me-n7 pe-7",
  id: "mobile_edit_licence_modal_scroll",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-max-height": "auto",
  "data-kt-scroll-dependencies": "#mobile_edit_licence_modal_header",
  "data-kt-scroll-wrappers": "#mobile_edit_licence_modal_scroll",
  "data-kt-scroll-offset": "300px"
}
const _hoisted_6 = { class: "d-flex flex-column mb-7 fv-row" }
const _hoisted_7 = { class: "form-check form-check-custom form-check-solid mt-4" }
const _hoisted_8 = { class: "form-check form-check-custom form-check-solid mt-4" }
const _hoisted_9 = { class: "form-check form-check-custom form-check-solid mt-4" }
const _hoisted_10 = { class: "form-check form-check-custom form-check-solid mt-4" }
const _hoisted_11 = { class: "form-check form-check-custom form-check-solid mt-4" }
const _hoisted_12 = { class: "form-check form-check-custom form-check-solid mt-4" }
const _hoisted_13 = { class: "form-check form-check-custom form-check-solid mt-4" }
const _hoisted_14 = { class: "form-check form-check-custom form-check-solid mt-4" }
const _hoisted_15 = { class: "d-flex flex-column mb-7 fv-row" }
const _hoisted_16 = { class: "form-check form-check-custom form-check-solid mt-4" }
const _hoisted_17 = { class: "form-check form-check-custom form-check-solid mt-4" }
const _hoisted_18 = { class: "form-check form-check-custom form-check-solid mt-4" }
const _hoisted_19 = { class: "form-check form-check-custom form-check-solid mt-4" }
const _hoisted_20 = { class: "form-check form-check-custom form-check-solid mt-4" }
const _hoisted_21 = { class: "form-check form-check-custom form-check-solid mt-4" }
const _hoisted_22 = { class: "form-check form-check-custom form-check-solid mt-4" }
const _hoisted_23 = { class: "modal-footer flex-center" }
const _hoisted_24 = ["disabled"]
const _hoisted_25 = ["data-kt-indicator", "disabled"]
const _hoisted_26 = {
  key: 0,
  class: "indicator-label"
}
const _hoisted_27 = {
  key: 1,
  class: "indicator-progress"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", {
    class: "modal fade",
    id: _ctx.modalId,
    ref: "MobileEditLicenceModal",
    tabindex: "-1",
    "aria-hidden": "true"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[63] || (_cache[63] = _createElementVNode("div", {
          class: "modal-header",
          id: "mobile_edit_licence_modal_header"
        }, [
          _createElementVNode("h2", { class: "fw-bolder" }, "Editar Licença")
        ], -1)),
        _createVNode(_component_el_form, {
          onSubmit: _cache[15] || (_cache[15] = _withModifiers(($event: any) => (_ctx.submit()), ["prevent"])),
          model: _ctx.formData,
          ref: "formRef"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", _hoisted_7, [
                    _createElementVNode("label", null, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input",
                        type: "radio",
                        name: "days",
                        value: "1",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.days) = $event))
                      }, null, 512), [
                        [_vModelRadio, _ctx.formData.days]
                      ]),
                      _cache[16] || (_cache[16] = _createTextVNode()),
                      _cache[17] || (_cache[17] = _createElementVNode("i", { class: "fad fa-circle-plus text-success fs-2" }, null, -1)),
                      _cache[18] || (_cache[18] = _createTextVNode(" Adicionar 1 Dia "))
                    ])
                  ]),
                  _createElementVNode("span", _hoisted_8, [
                    _createElementVNode("label", null, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input",
                        type: "radio",
                        name: "days",
                        value: "2",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.days) = $event))
                      }, null, 512), [
                        [_vModelRadio, _ctx.formData.days]
                      ]),
                      _cache[19] || (_cache[19] = _createTextVNode()),
                      _cache[20] || (_cache[20] = _createElementVNode("i", { class: "fad fa-circle-plus text-success fs-2" }, null, -1)),
                      _cache[21] || (_cache[21] = _createTextVNode(" Adicionar 2 Dias "))
                    ])
                  ]),
                  _createElementVNode("span", _hoisted_9, [
                    _createElementVNode("label", null, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input",
                        type: "radio",
                        name: "days",
                        value: "3",
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.days) = $event))
                      }, null, 512), [
                        [_vModelRadio, _ctx.formData.days]
                      ]),
                      _cache[22] || (_cache[22] = _createTextVNode()),
                      _cache[23] || (_cache[23] = _createElementVNode("i", { class: "fad fa-circle-plus text-success fs-2" }, null, -1)),
                      _cache[24] || (_cache[24] = _createTextVNode(" Adicionar 3 Dias "))
                    ])
                  ]),
                  _createElementVNode("span", _hoisted_10, [
                    _createElementVNode("label", null, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input",
                        type: "radio",
                        name: "days",
                        value: "7",
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.days) = $event))
                      }, null, 512), [
                        [_vModelRadio, _ctx.formData.days]
                      ]),
                      _cache[25] || (_cache[25] = _createTextVNode()),
                      _cache[26] || (_cache[26] = _createElementVNode("i", { class: "fad fa-circle-plus text-success fs-2" }, null, -1)),
                      _cache[27] || (_cache[27] = _createTextVNode(" Adicionar 7 Dias "))
                    ])
                  ]),
                  _createElementVNode("span", _hoisted_11, [
                    _createElementVNode("label", null, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input",
                        type: "radio",
                        name: "days",
                        value: "30",
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.days) = $event))
                      }, null, 512), [
                        [_vModelRadio, _ctx.formData.days]
                      ]),
                      _cache[28] || (_cache[28] = _createTextVNode()),
                      _cache[29] || (_cache[29] = _createElementVNode("i", { class: "fad fa-circle-plus text-success fs-2" }, null, -1)),
                      _cache[30] || (_cache[30] = _createTextVNode(" Adicionar 1 Mês (30 dias) "))
                    ])
                  ]),
                  _createElementVNode("span", _hoisted_12, [
                    _createElementVNode("label", null, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input",
                        type: "radio",
                        name: "days",
                        value: "90",
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.days) = $event))
                      }, null, 512), [
                        [_vModelRadio, _ctx.formData.days]
                      ]),
                      _cache[31] || (_cache[31] = _createTextVNode()),
                      _cache[32] || (_cache[32] = _createElementVNode("i", { class: "fad fa-circle-plus text-success fs-2" }, null, -1)),
                      _cache[33] || (_cache[33] = _createTextVNode(" Adicionar 3 Meses (90 dias) "))
                    ])
                  ]),
                  _createElementVNode("span", _hoisted_13, [
                    _createElementVNode("label", null, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input",
                        type: "radio",
                        name: "days",
                        value: "180",
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.days) = $event))
                      }, null, 512), [
                        [_vModelRadio, _ctx.formData.days]
                      ]),
                      _cache[34] || (_cache[34] = _createTextVNode()),
                      _cache[35] || (_cache[35] = _createElementVNode("i", { class: "fad fa-circle-plus text-success fs-2" }, null, -1)),
                      _cache[36] || (_cache[36] = _createTextVNode(" Adicionar 6 Meses (180 dias) "))
                    ])
                  ]),
                  _createElementVNode("span", _hoisted_14, [
                    _createElementVNode("label", null, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input",
                        type: "radio",
                        name: "days",
                        value: "365",
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formData.days) = $event))
                      }, null, 512), [
                        [_vModelRadio, _ctx.formData.days]
                      ]),
                      _cache[37] || (_cache[37] = _createTextVNode()),
                      _cache[38] || (_cache[38] = _createElementVNode("i", { class: "fad fa-circle-plus text-success fs-2" }, null, -1)),
                      _cache[39] || (_cache[39] = _createTextVNode(" Adicionar 12 Meses (365 dias) "))
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _cache[61] || (_cache[61] = _createElementVNode("span", { class: "form-check form-check-custom form-check-solid mt-4" }, [
                    _createElementVNode("label", null, [
                      _createElementVNode("input", {
                        class: "form-check-input",
                        type: "radio",
                        name: "days",
                        value: "-1",
                        "trav-model": "formData.days"
                      }),
                      _createTextVNode(),
                      _createElementVNode("i", { class: "fad fa-circle-minus text-danger fs-2" }),
                      _createTextVNode(" Remover 1 Dia ")
                    ])
                  ], -1)),
                  _createElementVNode("span", _hoisted_16, [
                    _createElementVNode("label", null, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input",
                        type: "radio",
                        name: "days",
                        value: "-2",
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formData.days) = $event))
                      }, null, 512), [
                        [_vModelRadio, _ctx.formData.days]
                      ]),
                      _cache[40] || (_cache[40] = _createTextVNode()),
                      _cache[41] || (_cache[41] = _createElementVNode("i", { class: "fad fa-circle-minus text-danger fs-2" }, null, -1)),
                      _cache[42] || (_cache[42] = _createTextVNode(" Remover 2 Dias "))
                    ])
                  ]),
                  _createElementVNode("span", _hoisted_17, [
                    _createElementVNode("label", null, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input",
                        type: "radio",
                        name: "days",
                        value: "-3",
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.formData.days) = $event))
                      }, null, 512), [
                        [_vModelRadio, _ctx.formData.days]
                      ]),
                      _cache[43] || (_cache[43] = _createTextVNode()),
                      _cache[44] || (_cache[44] = _createElementVNode("i", { class: "fad fa-circle-minus text-danger fs-2" }, null, -1)),
                      _cache[45] || (_cache[45] = _createTextVNode(" Remover 3 Dias "))
                    ])
                  ]),
                  _createElementVNode("span", _hoisted_18, [
                    _createElementVNode("label", null, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input",
                        type: "radio",
                        name: "days",
                        value: "-7",
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.formData.days) = $event))
                      }, null, 512), [
                        [_vModelRadio, _ctx.formData.days]
                      ]),
                      _cache[46] || (_cache[46] = _createTextVNode()),
                      _cache[47] || (_cache[47] = _createElementVNode("i", { class: "fad fa-circle-minus text-danger fs-2" }, null, -1)),
                      _cache[48] || (_cache[48] = _createTextVNode(" Remover 7 Dias "))
                    ])
                  ]),
                  _createElementVNode("span", _hoisted_19, [
                    _createElementVNode("label", null, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input",
                        type: "radio",
                        name: "days",
                        value: "-30",
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.formData.days) = $event))
                      }, null, 512), [
                        [_vModelRadio, _ctx.formData.days]
                      ]),
                      _cache[49] || (_cache[49] = _createTextVNode()),
                      _cache[50] || (_cache[50] = _createElementVNode("i", { class: "fad fa-circle-minus text-danger fs-2" }, null, -1)),
                      _cache[51] || (_cache[51] = _createTextVNode(" Remover 1 Mês (30 dias) "))
                    ])
                  ]),
                  _createElementVNode("span", _hoisted_20, [
                    _createElementVNode("label", null, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input",
                        type: "radio",
                        name: "days",
                        value: "-90",
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.formData.days) = $event))
                      }, null, 512), [
                        [_vModelRadio, _ctx.formData.days]
                      ]),
                      _cache[52] || (_cache[52] = _createTextVNode()),
                      _cache[53] || (_cache[53] = _createElementVNode("i", { class: "fad fa-circle-minus text-danger fs-2" }, null, -1)),
                      _cache[54] || (_cache[54] = _createTextVNode(" Remover 3 Meses (90 dias) "))
                    ])
                  ]),
                  _createElementVNode("span", _hoisted_21, [
                    _createElementVNode("label", null, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input",
                        type: "radio",
                        name: "days",
                        value: "-180",
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.formData.days) = $event))
                      }, null, 512), [
                        [_vModelRadio, _ctx.formData.days]
                      ]),
                      _cache[55] || (_cache[55] = _createTextVNode()),
                      _cache[56] || (_cache[56] = _createElementVNode("i", { class: "fad fa-circle-minus text-danger fs-2" }, null, -1)),
                      _cache[57] || (_cache[57] = _createTextVNode(" Remover 6 Meses (180 dias) "))
                    ])
                  ]),
                  _createElementVNode("span", _hoisted_22, [
                    _createElementVNode("label", null, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input",
                        type: "radio",
                        name: "days",
                        value: "-365",
                        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.formData.days) = $event))
                      }, null, 512), [
                        [_vModelRadio, _ctx.formData.days]
                      ]),
                      _cache[58] || (_cache[58] = _createTextVNode()),
                      _cache[59] || (_cache[59] = _createElementVNode("i", { class: "fad fa-circle-minus text-danger fs-2" }, null, -1)),
                      _cache[60] || (_cache[60] = _createTextVNode(" Remover 12 Meses (365 dias) "))
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("button", {
                type: "reset",
                id: "mobile_edit_licence_modal_cancel",
                class: "btn btn-light me-3",
                disabled: _ctx.loading
              }, " Cancelar ", 8, _hoisted_24),
              _createElementVNode("button", {
                "data-kt-indicator": _ctx.loading ? 'on' : null,
                class: "btn btn-lg btn-primary",
                type: "submit",
                disabled: _ctx.loading || _ctx.formData.days === 0
              }, [
                (!_ctx.loading)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_26, " Salvar "))
                  : _createCommentVNode("", true),
                (_ctx.loading)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_27, _cache[62] || (_cache[62] = [
                      _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle" }, null, -1)
                    ])))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_25)
            ])
          ]),
          _: 1
        }, 8, ["model"])
      ])
    ])
  ], 8, _hoisted_1))
}