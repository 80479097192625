import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.amount > 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(new Intl.NumberFormat('en-GB', {style: 'currency', currency: _ctx.code}).format(_ctx.amount)), 1))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, "-"))
  ]))
}