import { createElementVNode as _createElementVNode, vModelText as _vModelText, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-650px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-body py-10 px-lg-17" }
const _hoisted_5 = {
  class: "scroll-y me-n7 pe-7",
  id: "subscription_edit_modal_scroll",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-max-height": "auto",
  "data-kt-scroll-dependencies": "#subscription_edit_modal_header",
  "data-kt-scroll-wrappers": "#subscription_edit_modal_scroll",
  "data-kt-scroll-offset": "300px"
}
const _hoisted_6 = { class: "d-flex flex-column mb-7 fv-row" }
const _hoisted_7 = {
  key: 0,
  class: "d-flex flex-column mb-7 fv-row"
}
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "modal-footer flex-center" }
const _hoisted_10 = ["data-kt-indicator", "disabled"]
const _hoisted_11 = {
  key: 0,
  class: "indicator-label"
}
const _hoisted_12 = {
  key: 1,
  class: "indicator-progress"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", {
    class: "modal fade",
    id: _ctx.modalId,
    ref: "SubscriptionEditModal",
    tabindex: "-1",
    "aria-hidden": "true"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[5] || (_cache[5] = _createElementVNode("div", {
          class: "modal-header",
          id: "subscription_edit_modal_header"
        }, [
          _createElementVNode("h2", { class: "fw-bolder" }, "Editar Assinatura")
        ], -1)),
        _createVNode(_component_el_form, {
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.submit()), ["prevent"])),
          model: _ctx.formData,
          ref: "formRef"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _withDirectives(_createElementVNode("input", {
                    type: "email",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.user_email) = $event)),
                    class: "form-control form-control-solid w-full",
                    placeholder: "E-mail do Cliente (Precisa ser um cliente existente)"
                  }, null, 512), [
                    [_vModelText, _ctx.formData.user_email],
                    [_vShow, !(_ctx.mobiles && _ctx.mobiles.length)]
                  ]),
                  (_ctx.mobiles && _ctx.mobiles.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _cache[3] || (_cache[3] = _createElementVNode("div", null, "Dispositivos", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mobiles, (mobile) => {
                          return (_openBlock(), _createElementBlock("span", {
                            class: "form-check form-check-custom form-check-solid mt-4",
                            key: mobile.id
                          }, [
                            _createElementVNode("label", null, [
                              _withDirectives(_createElementVNode("input", {
                                class: "form-check-input",
                                type: "radio",
                                name: "mobile_id",
                                value: mobile.id,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.mobile_id) = $event))
                              }, null, 8, _hoisted_8), [
                                [_vModelRadio, _ctx.formData.mobile_id]
                              ]),
                              _createTextVNode(" " + _toDisplayString(mobile.manufacturer) + " " + _toDisplayString(mobile.model) + " " + _toDisplayString(mobile.android_version) + " (uid " + _toDisplayString(mobile.uid) + ") ", 1)
                            ])
                          ]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("button", {
                "data-kt-indicator": _ctx.loading ? 'on' : null,
                class: "btn btn-lg btn-primary",
                type: "submit",
                disabled: _ctx.loading
              }, [
                (!_ctx.loading)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, " Salvar "))
                  : _createCommentVNode("", true),
                (_ctx.loading)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, _cache[4] || (_cache[4] = [
                      _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle" }, null, -1)
                    ])))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_10)
            ])
          ]),
          _: 1
        }, 8, ["model"])
      ])
    ])
  ], 8, _hoisted_1))
}