import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "text-uppercase" }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", null, [
    (_ctx.country)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          _createTextVNode(_toDisplayString(_ctx.countryName) + " (", 1),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.country), 1),
          _cache[0] || (_cache[0] = _createTextVNode(")"))
        ]))
      : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.empty), 1))
  ]))
}