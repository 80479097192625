import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex flex-column flex-xl-row" }
const _hoisted_2 = { class: "flex-column flex-lg-row-auto w-100 w-xl-350px mb-10" }
const _hoisted_3 = { class: "card mb-5 mb-xl-8" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = {
  id: "kt_customer_view_details",
  class: "collapse show"
}
const _hoisted_6 = { class: "fs-6" }
const _hoisted_7 = { class: "text-gray-600" }
const _hoisted_8 = { class: "text-gray-600" }
const _hoisted_9 = {
  key: 0,
  class: "text-gray-600 text-capitalize"
}
const _hoisted_10 = { class: "text-gray-600" }
const _hoisted_11 = { class: "text-gray-600" }
const _hoisted_12 = { class: "text-gray-600" }
const _hoisted_13 = { class: "text-gray-600" }
const _hoisted_14 = { class: "flex-lg-row-fluid ms-lg-15" }
const _hoisted_15 = { class: "nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8" }
const _hoisted_16 = { class: "nav-item" }
const _hoisted_17 = {
  class: "nav-link text-active-primary pb-4 active",
  "data-bs-toggle": "tab",
  href: "#kt_customer_view_overview_events_and_logs_tab"
}
const _hoisted_18 = { class: "nav-item" }
const _hoisted_19 = {
  class: "nav-link text-active-primary pb-4",
  "data-bs-toggle": "tab",
  href: "#kt_payments_view"
}
const _hoisted_20 = { class: "nav-item" }
const _hoisted_21 = {
  class: "nav-link text-active-primary pb-4",
  "data-bs-toggle": "tab",
  href: "#kt_subscriptions_view"
}
const _hoisted_22 = {
  class: "tab-content",
  id: "myTabContent"
}
const _hoisted_23 = {
  class: "tab-pane fade show active",
  id: "kt_customer_view_overview_events_and_logs_tab",
  role: "tabpanel"
}
const _hoisted_24 = {
  class: "tab-pane fade",
  id: "kt_payments_view",
  role: "tabpanel"
}
const _hoisted_25 = {
  class: "tab-pane fade",
  id: "kt_subscriptions_view",
  role: "tabpanel"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Locale = _resolveComponent("Locale")!
  const _component_DateTime = _resolveComponent("DateTime")!
  const _component_Mobiles = _resolveComponent("Mobiles")!
  const _component_PaymentRecords = _resolveComponent("PaymentRecords")!
  const _component_SubscriptionsRecords = _resolveComponent("SubscriptionsRecords")!
  const _component_NewCardModal = _resolveComponent("NewCardModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _cache[0] || (_cache[0] = _createElementVNode("div", { class: "fw-bolder mt-5" }, "ID", -1)),
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.customer.id) + " ", 1),
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "fw-bolder mt-5" }, "E-mail", -1)),
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.customer.email) + "  ", 1),
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "fw-bolder mt-5" }, "Idioma", -1)),
                (_ctx.customer.locale)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(_component_Locale, {
                        locale: _ctx.customer.locale
                      }, null, 8, ["locale"])
                    ]))
                  : _createCommentVNode("", true),
                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "fw-bolder mt-5" }, "Cadastro", -1)),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_DateTime, {
                    "date-time": _ctx.customer.created_at
                  }, null, 8, ["date-time"])
                ]),
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "fw-bolder mt-5" }, "Atualizado em", -1)),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_DateTime, {
                    "date-time": _ctx.customer.updated_at
                  }, null, 8, ["date-time"])
                ]),
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "fw-bolder mt-5" }, "Último Login Painel", -1)),
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_DateTime, {
                    "date-time": _ctx.customer.last_login_panel
                  }, null, 8, ["date-time"])
                ]),
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "fw-bolder mt-5" }, "Último Login em um Dispositivo", -1)),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_DateTime, {
                    "date-time": _ctx.customer.last_login_device
                  }, null, 8, ["date-time"])
                ])
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("ul", _hoisted_15, [
          _createElementVNode("li", _hoisted_16, [
            _createElementVNode("a", _hoisted_17, "Dispositivos: " + _toDisplayString(_ctx.customer.mobiles.length), 1)
          ]),
          _createElementVNode("li", _hoisted_18, [
            _createElementVNode("a", _hoisted_19, " Vendas: " + _toDisplayString(_ctx.customer.orders.length), 1)
          ]),
          _createElementVNode("li", _hoisted_20, [
            _createElementVNode("a", _hoisted_21, " Assinaturas: " + _toDisplayString(_ctx.customer.subscriptions.length), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _createVNode(_component_Mobiles, {
              onCallback: _ctx.load,
              mobiles: _ctx.customer.mobiles,
              "card-classes": "mb-6 mb-xl-9"
            }, null, 8, ["onCallback", "mobiles"])
          ]),
          _createElementVNode("div", _hoisted_24, [
            _createVNode(_component_PaymentRecords, {
              orders: _ctx.customer.orders,
              "card-classes": "mb-6 mb-xl-9"
            }, null, 8, ["orders"])
          ]),
          _createElementVNode("div", _hoisted_25, [
            _createVNode(_component_SubscriptionsRecords, {
              onCallback: _ctx.load,
              subscriptions: _ctx.customer.subscriptions,
              mobiles: _ctx.customer.mobiles,
              "card-classes": "mb-6 mb-xl-9"
            }, null, 8, ["onCallback", "subscriptions", "mobiles"])
          ])
        ])
      ])
    ]),
    _createVNode(_component_NewCardModal)
  ], 64))
}