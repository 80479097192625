import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "badge badge-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.dateTime)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.dayjs(_ctx.dateTime).format('DD/MM/YYYY')), 1))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.empty), 1))
  ]))
}