
import {defineComponent, ref} from "vue";
import {hideModal} from "@/core/helpers/dom";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "subscription-edit-modal",
  props: {
    modalId: String,
    subscription: Object,
    subscriptionId: Number,
    mobiles: Array,
    mobileId: Number,
  },
  emits: {
    callback: () => true,
  },
  components: {},
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const SubscriptionEditModal = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const formData = ref({
      subscription_id: props.subscriptionId,
      mobile_id: props.mobileId,
      user_email: props.subscription?.user?.email
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(() => {
        loading.value = true;

        ApiService.setHeader();
        const payload = {
          params: {
            mobileId: formData.value.mobile_id,
            subscriptionId: formData.value.subscription_id,
            userEmail: formData.value.user_email
          }
        }
        ApiService.post(`v1/backend/mobile/move-subscription`, payload)
            .then(() => {
              loading.value = false;
              emit('callback')
              hideModal(SubscriptionEditModal.value);
            })
            .catch(({response}) => {
              console.log(response);
            });
      });
    };

    return {
      formData,
      submit,
      formRef,
      loading,
      SubscriptionEditModal,
    };
  },
});
