
import {defineComponent} from "vue";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(utc)
dayjs.extend(relativeTime)

export default defineComponent({
  props: {
    dateTime: {type: String},
    empty: {type: String, default: "Nunca"},
  },
  methods: {
    dayjs(dateTime) {
      return dayjs.utc(dateTime)
    }
  }
});
