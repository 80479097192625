
import {defineComponent, ref} from "vue";
import DateTime from "@/components/common/DateTime.vue";
import Currency from "@/components/common/Currency.vue";
import PaymentMethodLink from "@/components/common/PaymentMethodLink.vue";
import OrderStatus from "@/components/orders/OrderStatus.vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "payment-records",
  props: {
    cardClasses: String,
    orders: Array,
  },
  components: {
    OrderStatus,
    PaymentMethodLink,
    Currency,
    DateTime
  },
  computed: {
    ordersList() {
      let orders = this.orders
      return orders?.reverse()
    }
  },
  setup() {
    const processing = ref(false)

    const markAsCancelled = (order): void => {
      processing.value = true
      ApiService.setHeader()
      ApiService.put(`v1/backend/orders/mark-as-cancelled`, order)
          .then(() => {
            processing.value = false
            order.status = 2
          })
    }

    const markAsPaid = (order): void => {
      processing.value = true
      ApiService.setHeader()
      ApiService.put(`v1/backend/orders/mark-as-paid`, order)
      .then(() => {
        processing.value = false
        order.status = 1
      })
    }

    return {
      processing,
      markAsCancelled,
      markAsPaid,
    }
  }
});
