import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "badge badge-light-warning"
}
const _hoisted_2 = {
  key: 1,
  class: "badge badge-success"
}
const _hoisted_3 = {
  key: 2,
  class: "badge badge-danger"
}
const _hoisted_4 = {
  key: 3,
  class: "badge badge-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.status === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Teste"))
      : (_ctx.status === 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Ativo"))
        : (_ctx.status === 3)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Expirado"))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, "Excluído"))
  ]))
}