
import {defineComponent} from "vue";
import MobileDetails from "@/components/customers/MobileDetails.vue";

export default defineComponent({
  name: "mobiles",
  components: {
    MobileDetails
  },
  props: {
    cardClasses: String,
    mobiles: Array,
  },
  computed: {
    mobilesList() {
      let mobiles = this.mobiles
      return mobiles?.reverse()
    }
  },
  setup(props, {emit}) {
    const callback = (): void => {
      emit('callback')
    }

    return {
      callback
    }
  }
});
