import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]
const _hoisted_8 = {
  key: 4,
  class: "text-muted"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.order.payments.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.order.payments[0].gateway) + " (" + _toDisplayString(_ctx.order.payments[0].payment_method) + ") ", 1))
      : (_ctx.order.gateway)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.order.gateway) + " (" + _toDisplayString(_ctx.order.payment_method) + ") ", 1))
        : _createCommentVNode("", true),
    (_ctx.order.payments.length > 0 && _ctx.order.payments[0].gateway_transaction_id)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.order.payments[0].gateway.indexOf('paypal') > -1)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: `https://www.paypal.com/activity/payment/${_ctx.order.payments[0].gateway_transaction_id}`,
                target: "_blank"
              }, [
                _createTextVNode(_toDisplayString(_ctx.order.payments[0].gateway_transaction_id) + " ", 1),
                _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fad fa-external-link" }, null, -1))
              ], 8, _hoisted_4))
            : (_ctx.order.payments[0].gateway.indexOf('pagarme') > -1)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  href: `https://beta.dashboard.pagar.me/#/transactions/${_ctx.order.payments[0].gateway_transaction_id}`,
                  target: "_blank"
                }, [
                  _createTextVNode(_toDisplayString(_ctx.order.payments[0].gateway_transaction_id) + " ", 1),
                  _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fad fa-external-link" }, null, -1))
                ], 8, _hoisted_5))
              : (_ctx.order.payments[0].gateway.indexOf('stripe') > -1 && _ctx.order.payments[0].gateway_transaction_id.indexOf('sub_') > -1)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 2,
                    href: `https://dashboard.stripe.com/subscriptions/${_ctx.order.payments[0].gateway_transaction_id}`,
                    target: "_blank"
                  }, [
                    _createTextVNode(_toDisplayString(_ctx.order.payments[0].gateway_transaction_id) + " ", 1),
                    _cache[2] || (_cache[2] = _createElementVNode("i", { class: "fad fa-external-link" }, null, -1))
                  ], 8, _hoisted_6))
                : (_ctx.order.payments[0].gateway.indexOf('stripe') > -1 && _ctx.order.payments[0].gateway_transaction_id.indexOf('pi_') > -1)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 3,
                      href: `https://dashboard.stripe.com/payments/${_ctx.order.payments[0].gateway_transaction_id}`,
                      target: "_blank"
                    }, [
                      _createTextVNode(_toDisplayString(_ctx.order.payments[0].gateway_transaction_id) + " ", 1),
                      _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fad fa-external-link" }, null, -1))
                    ], 8, _hoisted_7))
                  : (_openBlock(), _createElementBlock("small", _hoisted_8, _toDisplayString(_ctx.order.payments[0].gateway_transaction_id || ' '), 1))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}