import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-650px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-body py-10 px-lg-17" }
const _hoisted_5 = {
  class: "scroll-y me-n7 pe-7",
  id: "mobile_move_subscription_modal_scroll",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-max-height": "auto",
  "data-kt-scroll-dependencies": "#mobile_move_subscription_modal_header",
  "data-kt-scroll-wrappers": "#mobile_move_subscription_modal_scroll",
  "data-kt-scroll-offset": "300px"
}
const _hoisted_6 = { class: "d-flex flex-column mb-7 fv-row" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "modal-footer flex-center" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = ["data-kt-indicator", "disabled"]
const _hoisted_11 = {
  key: 0,
  class: "indicator-label"
}
const _hoisted_12 = {
  key: 1,
  class: "indicator-progress"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", {
    class: "modal fade",
    id: _ctx.modalId,
    ref: "MobileMoveSubscriptionModal",
    tabindex: "-1",
    "aria-hidden": "true"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[5] || (_cache[5] = _createElementVNode("div", {
          class: "modal-header",
          id: "mobile_move_subscription_modal_header"
        }, [
          _createElementVNode("h2", { class: "fw-bolder" }, "Mover Assinatura")
        ], -1)),
        _createVNode(_component_el_form, {
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.submit()), ["prevent"])),
          model: _ctx.formData,
          ref: "formRef"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _cache[2] || (_cache[2] = _createElementVNode("label", { class: "fs-6 fw-bold mb-2" }, [
                    _createElementVNode("span", { class: "required" }, "Dispositivo de Destino")
                  ], -1)),
                  _cache[3] || (_cache[3] = _createElementVNode("div", { class: "my-2" }, "Atenção: A data de validade não será alterada.", -1)),
                  _createVNode(_component_el_select, {
                    modelValue: _ctx.formData.mobile_id,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.mobile_id) = $event))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mobiles, (mobile) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          value: mobile.id,
                          key: mobile.id
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" ID: " + _toDisplayString(mobile.id) + " - " + _toDisplayString(mobile.manufacturer) + " " + _toDisplayString(mobile.model) + " " + _toDisplayString(mobile.android_version) + " - APK " + _toDisplayString(mobile.apk_version) + " ", 1),
                            (mobile.alias)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_7, "(Alias: " + _toDisplayString(mobile.alias) + ")", 1))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("button", {
                type: "reset",
                id: "mobile_move_subscription_modal_cancel",
                class: "btn btn-light me-3",
                disabled: _ctx.loading
              }, " Cancelar ", 8, _hoisted_9),
              _createElementVNode("button", {
                "data-kt-indicator": _ctx.loading ? 'on' : null,
                class: "btn btn-lg btn-primary",
                type: "submit",
                disabled: _ctx.loading
              }, [
                (!_ctx.loading)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, " Salvar "))
                  : _createCommentVNode("", true),
                (_ctx.loading)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, _cache[4] || (_cache[4] = [
                      _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle" }, null, -1)
                    ])))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_10)
            ])
          ]),
          _: 1
        }, 8, ["model"])
      ])
    ])
  ], 8, _hoisted_1))
}