import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card-body p-0" }
const _hoisted_2 = { class: "d-flex flex-stack" }
const _hoisted_3 = { class: "m-0 text-white fw-bolder fs-3" }
const _hoisted_4 = { class: "shadow-xs card-rounded my-4 p-4 bg-white" }
const _hoisted_5 = { class: "text-gray-600" }
const _hoisted_6 = { class: "text-gray-600" }
const _hoisted_7 = { class: "text-gray-600" }
const _hoisted_8 = ["data-bs-target"]
const _hoisted_9 = { class: "text-gray-600" }
const _hoisted_10 = { class: "text-gray-600" }
const _hoisted_11 = { class: "text-gray-600" }
const _hoisted_12 = { class: "text-gray-600" }
const _hoisted_13 = { class: "text-gray-600 text-truncate" }
const _hoisted_14 = { class: "text-gray-600" }
const _hoisted_15 = { class: "text-gray-600" }
const _hoisted_16 = { class: "text-gray-600" }
const _hoisted_17 = { class: "shadow-xs card-rounded my-4 p-4 bg-white" }
const _hoisted_18 = { class: "d-flex" }
const _hoisted_19 = { class: "d-flex flex-wrap w-100" }
const _hoisted_20 = { class: "mb-1 pe-3 flex-grow-1" }
const _hoisted_21 = { class: "text-capitalize fw-bolder" }
const _hoisted_22 = { class: "text-gray-400 fw-bold fs-7" }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = {
  key: 0,
  class: "shadow-xs card-rounded my-4 p-4 bg-white fs-3 fw-bolder"
}
const _hoisted_25 = { key: 0 }
const _hoisted_26 = { key: 1 }
const _hoisted_27 = { class: "shadow-xs card-rounded my-4 p-4 bg-white" }
const _hoisted_28 = { class: "text-gray-600" }
const _hoisted_29 = {
  key: 0,
  class: "badge badge-success"
}
const _hoisted_30 = {
  key: 1,
  class: "badge badge-light-warning"
}
const _hoisted_31 = {
  key: 2,
  class: "badge badge-light-warning"
}
const _hoisted_32 = {
  key: 3,
  class: "badge badge-light-danger"
}
const _hoisted_33 = {
  key: 4,
  class: "badge badge-light-danger"
}
const _hoisted_34 = {
  key: 5,
  class: "badge badge-light-danger"
}
const _hoisted_35 = {
  key: 6,
  class: "badge badge-light-danger"
}
const _hoisted_36 = { class: "text-gray-600" }
const _hoisted_37 = { class: "text-gray-600" }
const _hoisted_38 = { class: "text-gray-600" }
const _hoisted_39 = { class: "text-gray-600" }
const _hoisted_40 = { class: "text-gray-600" }
const _hoisted_41 = { class: "text-gray-600" }
const _hoisted_42 = ["data-bs-target"]
const _hoisted_43 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DateTime = _resolveComponent("DateTime")!
  const _component_FromNow = _resolveComponent("FromNow")!
  const _component_MobileEditLicenceModal = _resolveComponent("MobileEditLicenceModal")!
  const _component_MobileStatus = _resolveComponent("MobileStatus")!
  const _component_Locale = _resolveComponent("Locale")!
  const _component_Country = _resolveComponent("Country")!
  const _component_Currency = _resolveComponent("Currency")!
  const _component_SubscriptionLink = _resolveComponent("SubscriptionLink")!
  const _component_MobileMoveSubscriptionModal = _resolveComponent("MobileMoveSubscriptionModal")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass([{
        'bg-warning': _ctx.mobile.status === 1,
        'bg-success': _ctx.mobile.status === 2,
        'bg-danger': [1,2].indexOf(_ctx.mobile.status) === -1
        }, "p-6 card-rounded w-100"])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.mobile.manufacturer) + " " + _toDisplayString(_ctx.mobile.model) + " " + _toDisplayString(_ctx.mobile.android_version) + " ", 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "fw-bolder" }, "Criação", -1)),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_DateTime, {
                "date-time": _ctx.mobile.created_at
              }, null, 8, ["date-time"]),
              _createVNode(_component_FromNow, {
                "date-time": _ctx.mobile.updated_at
              }, null, 8, ["date-time"])
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "fw-bolder mt-4" }, "Última Atualização", -1)),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_DateTime, {
                "date-time": _ctx.mobile.updated_at
              }, null, 8, ["date-time"]),
              _createVNode(_component_FromNow, {
                "date-time": _ctx.mobile.updated_at
              }, null, 8, ["date-time"])
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "fw-bolder mt-4" }, "Licença Expira em", -1)),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_DateTime, {
                "date-time": _ctx.mobile.licence_expires_at,
                empty: "-"
              }, null, 8, ["date-time"])
            ]),
            _createElementVNode("button", {
              class: "btn btn-primary mt-4 w-100",
              "data-bs-toggle": "modal",
              "data-bs-target": `#mobileEditLicence-${_ctx.mobile.id}`
            }, "Editar Licença ", 8, _hoisted_8),
            _createVNode(_component_MobileEditLicenceModal, {
              onCallback: _ctx.callback,
              "mobile-id": _ctx.mobile.id,
              "modal-id": `mobileEditLicence-${_ctx.mobile.id}`
            }, null, 8, ["onCallback", "mobile-id", "modal-id"]),
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "fw-bolder mt-4" }, "Status", -1)),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_MobileStatus, {
                status: _ctx.mobile.status
              }, null, 8, ["status"])
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "fw-bolder mt-4" }, "APK", -1)),
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.mobile.apk_version || '-'), 1),
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "fw-bolder mt-4" }, "Apelido", -1)),
            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.mobile.alias || '-'), 1),
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "fw-bolder mt-4" }, "UID", -1)),
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.mobile.uid), 1),
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "fw-bolder mt-4" }, "UUID", -1)),
            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.mobile.uuid), 1),
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "fw-bolder mt-4" }, "Idioma", -1)),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_Locale, {
                locale: _ctx.mobile.locale
              }, null, 8, ["locale"])
            ]),
            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "fw-bolder mt-4" }, "País", -1)),
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_Country, {
                country: _ctx.mobile.country
              }, null, 8, ["country"])
            ]),
            _cache[10] || (_cache[10] = _createElementVNode("div", { class: "fw-bolder mt-4" }, "Fuso Horário", -1)),
            _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.mobile.timezone || '-'), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_17, [
          (!_ctx.mobile.count.length)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(" Nenhum dado recebido até o momento ")
              ], 64))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.mobile.count, (count, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("div", _hoisted_21, _toDisplayString(count.table), 1),
                        _createElementVNode("div", _hoisted_22, [
                          _createVNode(_component_DateTime, {
                            "date-time": count.updated_at
                          }, null, 8, ["date-time"])
                        ])
                      ]),
                      _createElementVNode("div", null, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["p-2 fw-bolder rounded", {'bg-light-success': count.total > 0, 'bg-light-danger': count.total === 0}]),
                          title: "Total de registros",
                          style: {"cursor":"help"}
                        }, _toDisplayString(count.total), 3)
                      ])
                    ])
                  ]),
                  ((index + 1) < _ctx.mobile.count.length)
                    ? (_openBlock(), _createElementBlock("hr", _hoisted_23))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
        ]),
        (_ctx.mobile.subscriptions.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
              _createTextVNode(_toDisplayString(_ctx.mobile.subscriptions.length) + " ", 1),
              (_ctx.mobile.subscriptions.length > 1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_25, "Assinaturas"))
                : (_openBlock(), _createElementBlock("span", _hoisted_26, "Assinatura"))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_27, [
          (!_ctx.mobile.subscriptions.length)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(" Não possui assinatura ")
              ], 64))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.mobile.subscriptions, (subscription, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                  _createElementVNode("div", null, [
                    _cache[11] || (_cache[11] = _createElementVNode("div", { class: "fw-bolder fs-3" }, "Assinatura", -1)),
                    _cache[12] || (_cache[12] = _createElementVNode("div", { class: "fw-bolder mt-4" }, "ID", -1)),
                    _createElementVNode("div", _hoisted_28, _toDisplayString(subscription.id), 1),
                    _cache[13] || (_cache[13] = _createElementVNode("div", { class: "fw-bolder mt-4" }, "Situação", -1)),
                    _createElementVNode("div", null, [
                      (subscription.status === 'ACTIVE')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_29, "Paga"))
                        : (subscription.status === 'APPROVAL_PENDING')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_30, "Aguardando Pagamento"))
                          : (subscription.status === 'PENDING PAYMENT')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_31, "Aguardando Pagamento"))
                            : (subscription.status === 'EXPIRED')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_32, "Expirou"))
                              : (subscription.status === 'CANCELLED')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_33, "Cancelada"))
                                : (subscription.status === 'PAYMENT_CANCELLED')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_34, "Cancelada"))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_35, _toDisplayString(subscription.status), 1))
                    ]),
                    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "fw-bolder mt-4" }, "Cadastro", -1)),
                    _createElementVNode("div", _hoisted_36, [
                      _createVNode(_component_DateTime, {
                        "date-time": subscription.created_at
                      }, null, 8, ["date-time"])
                    ]),
                    _cache[15] || (_cache[15] = _createElementVNode("div", { class: "fw-bolder mt-4" }, "Atualização", -1)),
                    _createElementVNode("div", _hoisted_37, [
                      _createVNode(_component_DateTime, {
                        "date-time": subscription.created_at
                      }, null, 8, ["date-time"])
                    ]),
                    _cache[16] || (_cache[16] = _createElementVNode("div", { class: "fw-bolder mt-4" }, "Renova em", -1)),
                    _createElementVNode("div", _hoisted_38, [
                      _createVNode(_component_DateTime, {
                        "date-time": subscription.renew_at,
                        empty: "Não definido"
                      }, null, 8, ["date-time"])
                    ]),
                    _cache[17] || (_cache[17] = _createElementVNode("div", { class: "fw-bolder mt-4" }, "Adesão", -1)),
                    _createElementVNode("div", _hoisted_39, [
                      _createVNode(_component_Currency, {
                        amount: subscription.setup_fee,
                        code: subscription.currency.code
                      }, null, 8, ["amount", "code"])
                    ]),
                    _cache[18] || (_cache[18] = _createElementVNode("div", { class: "fw-bolder mt-4" }, "Recorrente", -1)),
                    _createElementVNode("div", _hoisted_40, [
                      _createVNode(_component_Currency, {
                        amount: subscription.price_recurring,
                        code: subscription.currency.code
                      }, null, 8, ["amount", "code"])
                    ]),
                    _cache[19] || (_cache[19] = _createElementVNode("div", { class: "fw-bolder mt-4" }, "Forma de Pagamento", -1)),
                    _createElementVNode("div", _hoisted_41, [
                      _createVNode(_component_SubscriptionLink, { subscription: subscription }, null, 8, ["subscription"])
                    ]),
                    (subscription.status === 'ACTIVE')
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: "btn btn-primary mt-4 w-100",
                          "data-bs-toggle": "modal",
                          "data-bs-target": `#mobileMoveSubscription-${subscription.id}`
                        }, "Mover Assinatura ", 8, _hoisted_42))
                      : _createCommentVNode("", true),
                    _createVNode(_component_MobileMoveSubscriptionModal, {
                      "mobile-id": _ctx.mobile.id,
                      mobiles: _ctx.mobiles,
                      subscription: subscription,
                      "modal-id": `mobileMoveSubscription-${subscription.id}`
                    }, null, 8, ["mobile-id", "mobiles", "subscription", "modal-id"])
                  ]),
                  ((index + 1) < _ctx.mobile.subscriptions.length)
                    ? (_openBlock(), _createElementBlock("hr", _hoisted_43))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
        ])
      ], 2)
    ])
  ], 2))
}