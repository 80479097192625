import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card-body p-6" }
const _hoisted_2 = { class: "dataTables_wrapper dt-bootstrap4 no-footer" }
const _hoisted_3 = { class: "table-responsive" }
const _hoisted_4 = {
  class: "table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer",
  role: "grid"
}
const _hoisted_5 = { class: "odd" }
const _hoisted_6 = { class: "align-text-top" }
const _hoisted_7 = { class: "align-text-top" }
const _hoisted_8 = { class: "align-text-top" }
const _hoisted_9 = { class: "align-text-top" }
const _hoisted_10 = { class: "align-text-top" }
const _hoisted_11 = { class: "align-text-top right" }
const _hoisted_12 = { class: "align-text-top text-end" }
const _hoisted_13 = { class: "align-text-top text-end" }
const _hoisted_14 = { class: "align-text-top text-end" }
const _hoisted_15 = { class: "align-text-top text-end" }
const _hoisted_16 = ["onClick", "disabled"]
const _hoisted_17 = ["onClick", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_DateTime = _resolveComponent("DateTime")!
  const _component_PaymentMethodLink = _resolveComponent("PaymentMethodLink")!
  const _component_OrderStatus = _resolveComponent("OrderStatus")!
  const _component_Currency = _resolveComponent("Currency")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`card ${_ctx.cardClasses}`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("table", _hoisted_4, [
            _cache[2] || (_cache[2] = _createElementVNode("thead", null, [
              _createElementVNode("tr", {
                class: "text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0",
                role: "row"
              }, [
                _createElementVNode("th", null, "Número"),
                _createElementVNode("th", null, "Data da Venda"),
                _createElementVNode("th", null, "Data de Atualização"),
                _createElementVNode("th", null, "Forma de Pagamento"),
                _createElementVNode("th", null, "Situação"),
                _createElementVNode("th", { class: "text-end" }, "Parcelas"),
                _createElementVNode("th", { class: "text-end" }, "Subtotal"),
                _createElementVNode("th", { class: "text-end" }, "Desconto"),
                _createElementVNode("th", { class: "text-end" }, "Total"),
                _createElementVNode("th", { class: "text-end" }, "Ações")
              ])
            ], -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ordersList, (order) => {
              return (_openBlock(), _createElementBlock("tbody", {
                class: "fw-bold text-gray-600",
                key: order.id
              }, [
                _createElementVNode("tr", _hoisted_5, [
                  _createElementVNode("td", _hoisted_6, [
                    _createVNode(_component_router_link, {
                      to: `/apps/orders/order-details/${order.id}`
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(order.order_number), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  _createElementVNode("td", _hoisted_7, [
                    _createVNode(_component_DateTime, {
                      "date-time": order.created_at
                    }, null, 8, ["date-time"])
                  ]),
                  _createElementVNode("td", _hoisted_8, [
                    _createVNode(_component_DateTime, {
                      "date-time": order.updated_at
                    }, null, 8, ["date-time"])
                  ]),
                  _createElementVNode("td", _hoisted_9, [
                    _createVNode(_component_PaymentMethodLink, { order: order }, null, 8, ["order"])
                  ]),
                  _createElementVNode("td", _hoisted_10, [
                    _createVNode(_component_OrderStatus, {
                      status: order.status
                    }, null, 8, ["status"])
                  ]),
                  _createElementVNode("td", _hoisted_11, _toDisplayString(order.installments), 1),
                  _createElementVNode("td", _hoisted_12, [
                    _createVNode(_component_Currency, {
                      code: order.currency.code,
                      amount: order.subtotal
                    }, null, 8, ["code", "amount"])
                  ]),
                  _createElementVNode("td", _hoisted_13, [
                    _createVNode(_component_Currency, {
                      code: order.currency.code,
                      amount: order.discount
                    }, null, 8, ["code", "amount"])
                  ]),
                  _createElementVNode("td", _hoisted_14, [
                    _createVNode(_component_Currency, {
                      code: order.currency.code,
                      amount: order.total
                    }, null, 8, ["code", "amount"])
                  ]),
                  _createElementVNode("td", _hoisted_15, [
                    (order.status === 0)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: "btn btn-success btn-sm btn-block",
                          onClick: ($event: any) => (_ctx.markAsPaid(order)),
                          disabled: _ctx.processing
                        }, _cache[0] || (_cache[0] = [
                          _createElementVNode("i", { class: "fad fa-dollar" }, null, -1),
                          _createTextVNode(" Marcar como Paga ")
                        ]), 8, _hoisted_16))
                      : _createCommentVNode("", true),
                    (order.status === 1)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 1,
                          class: "btn btn-danger btn-sm btn-block",
                          onClick: ($event: any) => (_ctx.markAsCancelled(order)),
                          disabled: _ctx.processing
                        }, _cache[1] || (_cache[1] = [
                          _createElementVNode("i", { class: "fad fa-x" }, null, -1),
                          _createTextVNode(" Cancelar Venda ")
                        ]), 8, _hoisted_17))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ], 2))
}