import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card-body p-6" }
const _hoisted_2 = { class: "dataTables_wrapper dt-bootstrap4 no-footer" }
const _hoisted_3 = { class: "table-responsive" }
const _hoisted_4 = {
  class: "table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer",
  role: "grid"
}
const _hoisted_5 = { class: "odd" }
const _hoisted_6 = { class: "align-text-top" }
const _hoisted_7 = { class: "align-text-top" }
const _hoisted_8 = { class: "align-text-top" }
const _hoisted_9 = { class: "align-text-top text-nowrap" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 2 }
const _hoisted_13 = { key: 3 }
const _hoisted_14 = { class: "align-text-top" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { class: "align-text-top text-end" }
const _hoisted_18 = { class: "align-text-top text-end" }
const _hoisted_19 = { class: "align-text-top" }
const _hoisted_20 = { class: "align-text-top" }
const _hoisted_21 = { class: "align-text-top" }
const _hoisted_22 = { class: "align-text-top text-end" }
const _hoisted_23 = ["data-bs-target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Date = _resolveComponent("Date")!
  const _component_Currency = _resolveComponent("Currency")!
  const _component_SubscriptionGatewayLink = _resolveComponent("SubscriptionGatewayLink")!
  const _component_SubscriptionStatus = _resolveComponent("SubscriptionStatus")!
  const _component_SubscriptionEditModal = _resolveComponent("SubscriptionEditModal")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`card ${_ctx.cardClasses}`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("table", _hoisted_4, [
            _cache[0] || (_cache[0] = _createElementVNode("thead", null, [
              _createElementVNode("tr", {
                class: "text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0",
                role: "row"
              }, [
                _createElementVNode("th", null, "ID"),
                _createElementVNode("th", null, "Data de Início"),
                _createElementVNode("th", null, "Data de Renovação"),
                _createElementVNode("th", null, "Pacote"),
                _createElementVNode("th", null, "Dispositivo"),
                _createElementVNode("th", null, "Valor de Adesão"),
                _createElementVNode("th", null, "Valor de Assinatura"),
                _createElementVNode("th", null, "Gateway"),
                _createElementVNode("th", null, "ID Gateway"),
                _createElementVNode("th", null, "Situação"),
                _createElementVNode("th", { class: "text-end" }, "Ações")
              ])
            ], -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subscriptionsList, (subscription) => {
              return (_openBlock(), _createElementBlock("tbody", {
                class: "fw-bold text-gray-600",
                key: subscription.id
              }, [
                _createElementVNode("tr", _hoisted_5, [
                  _createElementVNode("td", _hoisted_6, _toDisplayString(subscription.id), 1),
                  _createElementVNode("td", _hoisted_7, [
                    _createVNode(_component_Date, {
                      "date-time": subscription.start_date
                    }, null, 8, ["date-time"])
                  ]),
                  _createElementVNode("td", _hoisted_8, [
                    _createVNode(_component_Date, {
                      "date-time": subscription.renew_date,
                      empty: "Não definido"
                    }, null, 8, ["date-time"])
                  ]),
                  _createElementVNode("td", _hoisted_9, [
                    (subscription.package.months === 1)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_10, "1 Mês"))
                      : (subscription.package.months > 1)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(subscription.package.months) + " Meses", 1))
                        : (subscription.package.days === 1)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_12, "1 Dia"))
                          : (subscription.package.days > 1)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(subscription.package.days) + " Dias", 1))
                            : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("td", _hoisted_14, [
                    (!subscription.mobile_id)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, "Não vinculado à nenhum dispositivo"))
                      : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                          _createElementVNode("div", null, _toDisplayString(subscription.mobile.manufacturer) + " " + _toDisplayString(subscription.mobile.model) + " " + _toDisplayString(subscription.mobile.android_version), 1),
                          _createElementVNode("div", null, _toDisplayString(subscription.mobile.uid), 1)
                        ]))
                  ]),
                  _createElementVNode("td", _hoisted_17, [
                    _createVNode(_component_Currency, {
                      code: subscription.currency.code,
                      amount: subscription.setup_fee
                    }, null, 8, ["code", "amount"])
                  ]),
                  _createElementVNode("td", _hoisted_18, [
                    _createVNode(_component_Currency, {
                      code: subscription.currency.code,
                      amount: subscription.price_recurring
                    }, null, 8, ["code", "amount"])
                  ]),
                  _createElementVNode("td", _hoisted_19, _toDisplayString(subscription.gateway), 1),
                  _createElementVNode("td", _hoisted_20, [
                    _createVNode(_component_SubscriptionGatewayLink, { subscription: subscription }, null, 8, ["subscription"])
                  ]),
                  _createElementVNode("td", _hoisted_21, [
                    _createVNode(_component_SubscriptionStatus, {
                      status: subscription.status
                    }, null, 8, ["status"])
                  ]),
                  _createElementVNode("td", _hoisted_22, [
                    _createElementVNode("button", {
                      class: "btn btn-primary btn-sm btn-block",
                      "data-bs-toggle": "modal",
                      "data-bs-target": `#subscriptionEdit-${subscription.id}`
                    }, " Editar ", 8, _hoisted_23),
                    _createVNode(_component_SubscriptionEditModal, {
                      onCallback: _ctx.callback,
                      subscription: subscription,
                      "subscription-id": subscription.id,
                      mobiles: _ctx.mobiles,
                      "mobile-id": subscription.mobile_id,
                      "modal-id": `subscriptionEdit-${subscription.id}`
                    }, null, 8, ["onCallback", "subscription", "subscription-id", "mobiles", "mobile-id", "modal-id"])
                  ])
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ], 2))
}